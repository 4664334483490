import {
  getDocs,
  collection,
  query,
  where,
  doc,
  getDoc,
  updateDoc,
  serverTimestamp
} from "firebase/firestore";

import { db } from "../config/firebase";
export const deffectService = {
  getDeffectList,
  getProtocolById,
  Deffect_Corrected,
  Deffect_InCorrected,
  updateExpired,
  downloadPdfMangel,
  getDefectsByProtocolId,
  getNotRepairedDefectsByProtocolId,
  downloadPdf,
  getDeffectsNumber
};
async function getDefectsByProtocolId(id_protocol){
  try {
   const defectsRef = collection(db, "xc_defect");
   const q = query(defectsRef, where("id_protocol", "==", id_protocol));
   const querySnapshot = await getDocs(q);
   const defects = [];
   querySnapshot.forEach((docSnap) => {
     defects.push({ id: docSnap.id, ...docSnap.data() });
   });

   // Return the defects array
   return defects;
 } catch (error) {
   // Handle any errors during the fetch operation
   console.error("Error fetching defects:", error);
   return [];
 }
}

async function getNotRepairedDefectsByProtocolId(id_protocol){
  try {
   const defectsRef = collection(db, "xc_defect");
   const q = query(defectsRef, where("id_protocol", "==", id_protocol), where("status", "!=", "repaired"));
   const querySnapshot = await getDocs(q);
   const defects = [];
   querySnapshot.forEach((docSnap) => {
     defects.push(docSnap.data());
   });

   // Return the defects array
   return defects;
 } catch (error) {
   // Handle any errors during the fetch operation
   console.error("Error fetching defects:", error);
   return [];
 }
}
async function downloadPdfMangel(deffect_id) {
  const requestOptions = {
    method: "GET",
  };
  return fetch(
    `${process.env.REACT_APP_BASE_URL}/defect/export/${deffect_id}`,
    requestOptions
  ).then(handleResponse);
}
async function getProtocolById(id) { 
  const queryRef = query(
    collection(db, "xc_protocol"),
    where("id", "==", id)
  );
  const querySnapshot = await getDocs(queryRef);
  const protocol = await formatQuerySnapshotToArray(querySnapshot);
  return protocol[0];
}
async function Deffect_InCorrected(id,control = null) {
  let result ;
  const docRef = doc(db, "xc_defect", id);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    // Update the document
    if (control){
      await updateDoc(docRef, {
        status: "pending",
        controle_corrected:false,
        updated_at: serverTimestamp(),
        last_update_from_controle: serverTimestamp()
      });
    } else {
      await updateDoc(docRef, {
        status: "pending",
        controle_corrected:false,
        updated_at: serverTimestamp()
      });
    }
   
    result=true;
  } else {
    result=false;
  }
  return result 
}
async function updateExpired(id) {
  let result ;
  const docRef = doc(db, "xc_defect", id);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    // Update the document
    await updateDoc(docRef, {
      status: "expired",
      updated_at:serverTimestamp()
    });
    result=true;
  } else {
    result=false;
  }
  return result 
}
async function Deffect_Corrected(id,control = null) {
  let result ;
  const docRef = doc(db, "xc_defect", id);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    // Update the document
    if (control){
      await updateDoc(docRef, {
        status: "repaired",
        controle_corrected:true,
        updated_at:serverTimestamp(),
        last_update_from_controle: serverTimestamp()
      });
    } else {
      await updateDoc(docRef, {
        status: "repaired",
        controle_corrected:true,
        updated_at:serverTimestamp()
      });
    }
    
    result=true;
  } else {
    result=false;
  }
  return result 
}
async function getDeffectList(value, sortBy, filterOptions,filterValue,limit,DeffectTypeCondition,DeffectNameCondition) {
  const params = new URLSearchParams();

  const requestOptions = {
    method: "GET",
  };
  // Append each filter as individual query parameters
  params.append("company_id", value);
  params.append("filter", filterValue);
  params.append("limit", limit);
  if (sortBy) {
    params.append("sort_by", sortBy);
  }
  if(!DeffectNameCondition)
  {  if (filterOptions && filterOptions.deffect_name) {
    filterOptions.deffect_name.forEach((name) =>
      params.append("defect_name", name)
    );
  }}
  if(!DeffectTypeCondition){
  if (filterOptions && filterOptions.deffect_type) {
    filterOptions.deffect_type.forEach((name) =>
      params.append("defect_type", name)
    );
  }}
  if (filterOptions && filterOptions.status) {
    filterOptions.status.forEach((name) => params.append("status", formatStatus(name)));
  }
  if (filterOptions && filterOptions.room_deffect_who_fix) {
    filterOptions.room_deffect_who_fix.forEach((name) =>
      params.append("room_deffect_who_fix", name)
    );
  }
  // Use the constructed URL with query parameters
  return fetch(
    `${`${process.env.REACT_APP_BASE_URL}/defect/all`}?${params.toString()}`,
    requestOptions
  ).then(handleResponse);
}
async function downloadPdf(company_id) {
  const requestOptions = {
    method: "GET",
  };
  return fetch(
    `${process.env.REACT_APP_BASE_URL}/defect/allExport/${company_id}`,
    requestOptions
  ).then(handleResponse);
}

function formatStatus(status) {
  switch (status) {
    case "behoben":
      return "repaired";
    case "offen / Frist überschritten":
      return "expired";
    case "noch in der Frist":
      return "pending";
  }
}
const formatQuerySnapshotToArray = async (querySnapshot) => {
  const dataArray = [];

  querySnapshot.forEach((doc) => {
    const data = doc.data();
    dataArray.push(data);
  });

  return dataArray;
};
  async function getDeffectsNumber(company_id,status) {
    const queryRef = query(
      collection(db, "xc_defect"),
      where("company_id", "==", company_id),
      where("status", "==", status)
    );
    
    const querySnapshot = await getDocs(queryRef);
    
    // Filter out defects where room_deffect_who_fix is not "accepted"
    const filteredData = querySnapshot.docs.filter((doc) => doc.data().room_deffect_who_fix !== "accepted");
  
    // Return the number of filtered defects
    const numberOfExpiredDefects = filteredData.length;
  
    return numberOfExpiredDefects;
  }
  


// handle response
function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      const error = (data && data.msg) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}
