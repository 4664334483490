import {
  doc,
  getDocs,
  updateDoc,
  collection,
  query,
  where,
  setDoc,
  deleteDoc,
  getDoc,
  orderBy,
  //limit,
  startAfter,
} from "firebase/firestore";
import { db } from "../config/firebase";
import { header } from "react-dom-factories";

export const logBookService = {
  getList,
  getListByRealState,
  getByDoc,
  getDraftProtocolList,
  getPausedProtocolList,
  getClosedProtocolList
};
async function getList(
  type,
  status,
  limit,
  created_by,
  date,
  pageNumber,
  name,
  filter,
  actual_status,
  field,
  value
) {
  let baseUrl = `${process.env.REACT_APP_BASE_URL}/logBook/getAll`;
  // Append query parameters to the base URL
  const queryParams = new URLSearchParams({
    type,
    status,
    limit,
    created_by,
    date,
    pageNumber,
    name,
    filter,
    actual_status,
    field,
    value,
  });

  const requestOptions = {
    method: "GET",
  };

  // Use the constructed URL with query parameters
  return fetch(
    `${`${process.env.REACT_APP_BASE_URL}/logBook/getAll`}?${queryParams.toString()}`,
    requestOptions
  ).then(handleResponse);
}

async function getListByRealState(
  type,
  status,
  limit,
  created_by,
  date,
  pageNumber,
  name,
  filter,
  actual_status,
  field,
  value,
  id,
  location
) {
  
  // Append query parameters to the base URL
  const queryParams = new URLSearchParams({
    type,
    status,
    limit,
    created_by,
    date,
    pageNumber,
    name,
    filter,
    actual_status,
    field,
    value,
    id,
    location
  });

  const requestOptions = {
    method: "GET",
  };

  // Use the constructed URL with query parameters
  return fetch(
    `${`${process.env.REACT_APP_BASE_URL}/logBook/getAll`}?${queryParams.toString()}`,
    requestOptions
  ).then(handleResponse);
}

 async function getByDoc(id,type) {
  try {
    const queryRef = query(
      collection(db, "xc_logbook"),
      where("doc_type","==",type),
      where("id_doc","==",id)
    );
    const querySnapshot = await getDocs(queryRef);
    const formattedArray = await formatQuerySnapshotToArray(querySnapshot);
    if (!querySnapshot.empty) {
      return querySnapshot.docs;
    } else {
      return null;
    }
  } catch (error) {
    console.error("Error fetching document:", error);
    throw error; // Throw the error for handling further up the chain
  }
}
//get draft protocol list by user
async function getDraftProtocolList(field, id) {
  const queryRef = query(
    collection(db, "xc_logbook"),
    where("progress_status", "==", "draft"),
    where("status", "==", "active"),
    where("doc_type","in",["protocol", "protocol_control"]),
    where(field, "==", id)
  );
  const querySnapshot = await getDocs(queryRef);
  const formattedArray = await formatQuerySnapshotToArray(querySnapshot);
  return formattedArray;
}
async function getPausedProtocolList(field, id) {
    const queryRef = query(
    collection(db, "xc_logbook"),
    where("progress_status", "==", "paused"),
    where("status", "==", "active"),
    where("doc_type","in",["protocol", "protocol_control"]),
    where(field, "==", id)
  );
  const querySnapshot = await getDocs(queryRef);
  const formattedArray = await formatQuerySnapshotToArray(querySnapshot);
  return formattedArray;
}
async function getClosedProtocolList(field, id) {
  const queryRef = query(
  collection(db, "xc_logbook"),
  where("progress_status", "==", "closed"),
  where("status", "==", "active"),
  where("doc_type","in",["protocol", "protocol_control"]),
  where(field, "==", id)
);
const querySnapshot = await getDocs(queryRef);
const formattedArray = await formatQuerySnapshotToArray(querySnapshot);
return formattedArray;
}

const formatQuerySnapshotToArray = async (querySnapshot) => {
  const dataArray = [];

  querySnapshot.forEach((doc) => {
    const data = doc.data();
    dataArray.push(data);
  });

  return dataArray;
};
/*async function updateContract(contract_id, contract) {
    const docRef = doc(db, "xc_logBook", contract_id);
    return await updateDoc(docRef, contract);
  }µ/
  
  /*----------------------------------------------------------------*/
/*async function getContractListlength(user_id) {
    const queryRef = query(
      collection(db, "xc_logBook"),
      where("contractStatus", "==", "active"),
      where("created_by", "==", user_id)
    );
    const querySnapshot = await getDocs(queryRef);
    const documentCount = querySnapshot.size;
    return documentCount;
  }
  
  async function getContractindraftListlength(user_id) {
    const queryRef = query(
      collection(db, "xc_logBook"),
      where("contractStatus", "==", "inDraft"),
      where("created_by", "==", user_id)
    );
    const querySnapshot = await getDocs(queryRef);
    const documentCount = querySnapshot.size;
    return documentCount;
  }
  
  async function getContractListbystatusnotclosed(user_id) {
    const queryRef = query(
      collection(db, "xc_logBook"),
      where("contractStatus", "==", "active"),
      where("created_by", "==", user_id),
      where("product_sealed", "==", false)
    );
    const querySnapshot = await getDocs(queryRef);
    const formattedArray = await formatQuerySnapshotToArray(querySnapshot);
    return formattedArray;
  }
  
  async function getContractLimitList(user_id, paginationsize) {
    let size = paginationsize ? paginationsize : 10;
    const queryRef = query(
      collection(db, "xc_logBook"),
      where("contractStatus", "==", "active"),
      where("created_by", "==", user_id),
      limit(size)
    );
    const querySnapshot = await getDocs(queryRef);
    const formattedArray = await formatQuerySnapshotToArray(querySnapshot);
    return formattedArray;
  }
  
  async function getContractLimitListbyType(user_id, paginationsize, type) {
    let size = paginationsize ? paginationsize : 10;
    const queryRef = query(
      collection(db, "xc_logBook"),
      where("product_type", "==", type),
      where("contractStatus", "==", "active"),
      where("created_by", "==", user_id),
      limit(size)
    );
    const querySnapshot = await getDocs(queryRef);
    const formattedArray = await formatQuerySnapshotToArray(querySnapshot);
    return formattedArray;
  }
  
  async function getinDraftContractLimitList(user_id, paginationsize) {
    let size = paginationsize ? paginationsize : 10;
    const queryRef = query(
      collection(db, "xc_logBook"),
      where("contractStatus", "==", "inDraft"),
      where("created_by", "==", user_id),
      limit(size)
    );
    const querySnapshot = await getDocs(queryRef);
    const formattedArray = await formatQuerySnapshotToArray(querySnapshot);
    return formattedArray;
  }*/
/*----------------------------------------------------------------*/

// handle response
function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}
