import React, { useEffect, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import { serverTimestamp } from "firebase/firestore";
import { Dialog, DialogContent } from "@mui/material";
import swal from "sweetalert";
import { companyService, userService } from "_services";
import { promocodeService } from "_services/promo-code.service";
import { TextField } from "@material-ui/core";
import { useHistory } from "react-router-dom";

const PromoCodePopup = ({
  show,
  setShow,
}) => {
  const history = useHistory();
  const user = userService.getId();
  const [errors, setErrors] = useState({});
  const [promocodeId, setPromocodeId] = useState();
  //Initialize the Notification state
  const [promoCode, setPromoCode] = useState("");
  const [company, setCompany] = useState({});
  // Get data from inputs
  function handleChange(e) {
    setPromoCode(e.target.value);
  }
  const getCompanyInfo = async () => {
    const companyId = await companyService.getCompanyID(
      "xc_user",
      userService.getId()
    );
    const companyInfo = await companyService.getCompany(companyId);
    setCompany(companyInfo);
  };
  function TranslationSubject(englishWord) {
    const translations = {
      "Promo code not found.": "Der Promo-Code konnte nicht gefunden werden",
      "Promo code has expired.":
        "Der Promo-Code ist abgelaufen",
      "Promo code has reached its usage limit":
        "Der Promo-Code nicht mehr gültig",
    };
    return translations[englishWord] || "Im Promo-Code ist ein Fehler aufgetreten";
  }
  //Validate required fields
  function validateFormData() {
    const errors = {};

    if (!promoCode) {
      errors.sending_method = true;
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  }

  //add promocode
  const onSubmit = async (event) => {
    event.preventDefault();
    if (validateFormData()) {
      try {
        promocodeService.ValidePromoCode(promoCode).then(async (result) => {
          if (result.error) {
            swal({
              title: "HINWEIS",
              icon: "error",
              text: `${TranslationSubject(result.error)}`,
              button: "OK",
            }).then((willCreate) => {
              if (willCreate) {

                history.push("/app/overview");
              }
            });
          } else if (result.msg) {
            setPromocodeId(result.data.id)
            swal({
              title: "HINWEIS",
              icon: "success",
              text: "Promo-Code erfolgreich angewendet",
              button: "OK",
            }).then(async (willCreate) => {
              if (willCreate) {
                await handleUpdateCompany();
                await handleUpdatePromoCode(result.data.id);
                history.push("/app/overview");
              }
            });
          }
        });
      } catch (err) {
        console.error(err);
      }
    } else {
      swal({
        title: "HINWEIS",
        icon: "error",
        text: "Bitte füllen Sie alle Felder aus!",
        button: "OK",
      }).then((willVerify) => {
        if (willVerify) {
          history.push("#");
        }
      });
    }
  };
  const handleUpdatePromoCode = async (id) => {
    const userId = userService.getId();
    await promocodeService.updatePromoCode(userId, id);
  };
  const handleUpdateCompany = async () => {

    const updatedCompany = { ...company };
    updatedCompany.company_account_type = "premium";
    updatedCompany.company_profile_updatedAt = serverTimestamp();
    await companyService.updatedCompany(updatedCompany);
  };
  useEffect(() => {
    setTimeout(() => {
      getCompanyInfo();
    }, 2000);
  }, []);

  // Handler for closing the dialog
  const handleCloseDialog = () => {

    setShow(false);
    history.push("/app/overview");
  };

  return (
    <Dialog
      className={`after-cards  dialog ${show ? "show" : ""}`}
      style={{ backgroundColor: "transparent" }}
      sx={{
        "& .MuiDialog-paper": {
          width: "100%",
          height: "100%",
          maxWidth: 700,
          maxHeight: 360,
          borderRadius: "inherit",
        },
      }}
      maxWidth="xs"
      open={show}
      onClose={() => {
        setShow(false)
        history.push("/app/overview");
      }}
    >
      <div className="Promocode dialog-content textfieldsdateplzObOw padding-top-bottom pr-0 pl-0">
        {/* Close Icon */}
        <div className="close-icon" onClick={handleCloseDialog}>
          <CloseIcon />
        </div>
        {/* Input with Autocomplete */}
        <DialogContent
          dividers
          sx={{
            textTransform: "uppercase",
            borderBottom: "none",
            borderTop: "none",
            overflowY: "hidden !important", // Add this to disable vertical scrolling

          }}
        >
          <div className="after-cards mt-3 w-100">
            <div className="layoutstepsbar" style={{ height: "55px" }}>
              <div class="row layoutcontent full-height">
                <div className="einzug ">
                  <h3>X-CITE PROMOTION</h3>
                </div>
              </div>
            </div>
            <div className="textfieldsdateplzObOw mt-2">
              <p style={{ fontSize: "16px" }}>
                Geben Sie den Promotionscode ein, den Sie von der X-SMART
                erhalten haben
              </p>
              <div className="promocode row mt-2 mb-2">
                <div className="col-md-12">
                  <TextField
                    InputLabelProps={{
                      style: {
                        textTransform: "uppercase",
                        padding: "0 5px 0 0",
                        backgroundColor: "white",
                      },
                      //shrink: "true",
                    }}
                    name="promo_code"
                    id="promo_code"
                    value={promoCode}
                    type="text"
                    label="Ihr Promotionscode hier eintragen"
                    variant="outlined"
                    fullWidth
                    onChange={(e) => handleChange(e, "promo_code")}
                    required
                    error={errors.content}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="OvwerviewGlobalButton w-100 d-flex justify-content-center">
            <button className=" col-6 " type="button" onClick={onSubmit}>
              {/* Versenden */}
              JETZT PREMIUMKUNDE WERDEN
            </button>
          </div>
        </DialogContent>
        {/* Save Options */}
      </div>
    </Dialog>
  );
};

export default PromoCodePopup;
